@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');


.tabItem{
    border: 2px solid #f9a22d !important;
    border-radius: 0px  !important;
}

.activate {
  background-color: #f9a22d;
  color: #fff !important;
}

.fntRobo {
    font-family: "Roboto"
}

.main {
    padding: 5.5% 8%;
    color: #555555;
    cursor: pointer;
    border-bottom: none !important;
}

.main:active {
    background-color: #f9a22d;
    color: #fff !important
    
}

.main:hover {
    background-color: #f9a22d;
    color: #fff !important
}

.common {
    background-color: rgb(175 173 166 / 20%) !important;
}

.tabSteps {
 
    font-size: 16px;
}

.fntBold {
  font-weight: 1000 !important;
  font-size: 25px;
}

.artistCenter {
    text-align: center;
    padding: 0% 1.5%;
    font-size: 130%;
    font-weight: 800;
}

.uploadTxt {
    padding: 0% 1.5%;
    font-size: 130%;
    font-weight: 800;
}

hr {
    border-color: #f9a22d
}

.basicBox {
    color: #000;
    background-color: rgb(175 173 166 / 20%) !important;
    border-radius: 0px  !important;
    border: 2px solid #f9a22d !important;
    margin-left: 10% !important;
    margin-right: 10% !important;
    width: 100%;
}
.checkGrid {
  color: #000;
    background-color: rgb(175 173 166 / 20%) !important;
    border-radius: 0px  !important;
    border: 2px solid #f9a22d !important;
    width: 70% !important;
    margin: 0px 10% !important;
}


.btnPrimary {
    background-color: #f9a22d !important;
}

.textChk {
    background: #fff;
    color: #525865;
    border-radius: 4px;
    border: 1px solid #d1d1d1;
    box-shadow: inset 1px 2px 8px rgba(0, 0, 0, 0.07);
    font-family: inherit;
    font-size: 1em;
    line-height: 1.45;
    outline: none;
    padding: 0.1em 0.15em 0.7em;
    -webkit-transition: .18s ease-out;
    -moz-transition: .18s ease-out;
    -o-transition: .18s ease-out;
    transition: .18s ease-out;
  }
  .textChk:hover {
    box-shadow: inset 1px 2px 8px rgba(0, 0, 0, 0.02);
  }
  .textChk:focus {
    color: #4b515d;
    border: 1px solid #B8B6B6;
    box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.01), 0px 0px 8px rgba(0, 0, 0, 0.2);
  }

  .sixtyfive {
    width: 55%;
  }

  .hundred {
    width: 81.5%;
  }

  .eightynine {
    width: 99%;
  
  }
.thing {
  padding: 0px 11.5%;
}
  .widthChk {
    width: 98%;
  }

  .pd10 {
    padding-top: 40px !important;
  }

  .widthCh {
    padding: 0px 13% !important;
  }


  @media only screen and (min-width: 900px) {
    .tabNames {
    
      font-size: 120%;
      font-weight: 800;
  }

  .chkCenter {
    justify-content: right;
    display: flex;
    margin-right: 10% !important;
    margin-top: 1.5% !important;
  }

  .goRight {
    text-align: right;
    margin-top: 1.5% !important;
    padding-right: 3% !important;
}

.goChk {
  text-align: right;
  margin-top: 1.5% !important;
  padding-right: 13% !important;
}

.goCenter {

    text-align: center;
    margin-top: 1.5% !important;

}


  }

  @media only screen and (max-width: 900px) {
    .tabNames {
    
      font-size: 67%;
      font-weight: 800;
  }

  .goRight {
    text-align: center;
margin-top: 1.5% !important;
}

.goChk {
  text-align: center;
  margin-top: 1.5% !important;
}

.goCenter {

    text-align: center;
    margin-top: 1.5% !important;

}

.chkCenter {
  justify-content: center;
  display: flex;
  margin-top: 1.5% !important;
}

  }

  .fntSmal {
     font-size: 80%;
  }

  option:hover {
    background: #f9a22d !important;
    color: #fff !important;
  }