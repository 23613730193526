@media only screen and (min-width: 600px) {

  .navNot {
    background: transparent !important;
    position: fixed !important;
    z-index: 111 !important;
    box-shadow: none !important;
  }

.navActive {
  background: #333 !important;
  position: fixed !important;
  z-index: 111 !important;
  box-shadow: none !important;
}

.menuItem {
  margin: 0px 20px !important;
}
    .banner h1 {
        color: #fff;
        position: relative;
        left: 52%;
        top: 52%;
        min-width: 68%;
        transform: translateX(-32%);
        font: normal normal bold 32px/50px Josefin Sans !important;
    }
    .banner h2 {
      color: #fff;
      position: relative;
      left: 90%;
      font: normal normal normal 32px/80px Playfair Display;
      top: 52%;
      white-space: nowrap;
      transform: translateX(-50%);
  }
  .banner .button {
    display: block;
    position: relative;
    left: 50%;
    top: 60%;
    height: 2.5rem;
    padding-top: 1.3rem;
    width: 15rem;
    text-align: center;
    color: #fff;
    border: #fff solid 0.2rem;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    transform: translateX(-50%);
    cursor: pointer;
    background-color: rgba(14, 13, 13, 0.6);
    font-family: 'Josefin Sans' !important;
  }

  .hover-right .banner h1 {
    width: 25%;
    left: 30% !important
  
  }

  .onlyBtn {
    width: 130px;
    height: 45px;
    font-size: 17px !important;
  }
}

@media only screen and (max-width: 600px) {
    .banner h1 {
        color: #fff;
        position: absolute;
        left: 42%;
        top: 39%;
        min-width: 68%;
        transform: translateX(-32%);
        font: normal normal bold 20px/40px Josefin Sans !important;
    }

    .centr {
      text-align: center;
    }
  .onlyBtn {

  }
  .navNot {
    background: transparent !important;
    position: fixed !important;
    z-index: 111 !important;
    box-shadow: none !important;
  }

  .navActive {
    background: #333 !important;
    position: fixed !important;
    z-index: 111 !important;
    box-shadow: none !important;
  }
}

.newButton{
  color: #191818 !important;
  background: #fff !important;
}

.newButton:hover{
  color: #fff !important;
  background: rgba(179, 169, 58, 0.56) !important;
}

.dilogpopup > .MuiDialog-scrollPaper > .MuiPaper-elevation{
  max-width: 90%;
}

.widths > .MuiDialog-scrollPaper .MuiPaper-elevation{
  width: 70%;
}

.Ditital > .MuiDialog-scrollPaper .MuiPaper-elevation{
  width: 100%;
}

@media only screen and (min-width: 700px) {
  .BoldSeven {
    /* font-size: 26px;  */
    line-height: 113%;
  }
  .aboutRef {
    padding-top: 200px !important;
  }
  .serviceRef{
    padding-top: 200px !important;
  }
.teamRef{
  padding-top: 100px;
}
}