/* our services style */
.services-card-image{
    height: 48px;
    margin-bottom: 18px;
    padding: 10px;
    border-radius: 10px;
    background: black;

}

/* our services style */  


/* footer style */


    .hover-link:hover {
        color: #FFD700; /* Changes text color to gold on hover */
        text-decoration: underline; /* Adds underline on hover */
    }

/* footer style */